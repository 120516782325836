import { LinearProgress } from "@material-ui/core"
import { lazy, Suspense } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import userTypes, { ACCOUNT_MANAGER, CUSTOMER_MANAGER, PROPERTY_MANAGER, SERVICE_MANAGER } from "../config/userType"
import authGuard, { AuthGuard } from "../guard/authGuard"
import guestGuard from "../guard/guestGuard"

export default function Router() {
  let location = useLocation()

  // This piece of state is set when one of the gallery links is clicked. The `background` state is the location that we were at when one of
  // the gallery links was clicked. If it's there, use it as the location for the <Switch> so we show the gallery in the background, behind the modal.
  let background = location.state && location.state.background

  return (
    <>
      <Switch location={background || location}>
        <Route exact path={["/", "/user/property-manager/new", "/user/new"]} component={authGuard(DashboardPage, Object.values(userTypes))} />
        <Route exact path="/auth/login" component={guestGuard(Login)} />
        <Route exact path="/auth/forgot-password" component={ForgotPassword} />
        {/* don't change this address, its use in backend also */}
        <Route exact path="/auth/reset-password" component={ResetPassword} />
        <Route exact path="/property" component={authGuard(PropertyListPage, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/property/new" children={AuthGuard({ children: AddPropertyPage })} />
        <Route exact path="/property/request" component={authGuard(OwnerRequest, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/property/user/waiting" component={authGuard(WaitingListPage, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/property/meter-reading" component={authGuard(CreateMeterReadingPage)} />
        <Route exact path="/property/:id/meter-reading" component={authGuard(PropertyMeterReadingPage)} />
        <Route exact path="/property/:id" component={authGuard(PropertyDetails, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/reservation" component={authGuard(ReservationPage, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/reservation/bookings" component={authGuard(BookingListPage, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/reservation/bookings/create" component={authGuard(CreateReservationPage, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/reservation/bookings/:booking_id" component={authGuard(BookingDetailsPage, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/reservation/internal-shift/:booking_id" component={authGuard(InternalShiftPage, [CUSTOMER_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/reservation/settlement" component={authGuard(SettlementPage, [ACCOUNT_MANAGER])} />

        {/* currently not in use */}
        <Route exact path="/reservation/moveout" component={authGuard(CreateMoveOutPage, [ACCOUNT_MANAGER])} />

        <Route exact path="/reservation/enquiry" component={authGuard(EnquiryListPage, [CUSTOMER_MANAGER])} />
        <Route exact path="/reservation/visits" component={authGuard(VisitListPage, [PROPERTY_MANAGER])} />
        <Route exact path="/reservation/invoices" component={authGuard(InvoicePage, [ACCOUNT_MANAGER])} />
        <Route exact path="/reservation/invoices/add-overdue" component={authGuard(InvoiceAddOverduePage, [ACCOUNT_MANAGER])} />
        {/* currently not in use */}
        <Route exact path="/reservation/invoices/new" component={authGuard(InvoiceGeneratePage, [ACCOUNT_MANAGER])} />
        <Route exact path="/reservation/invoices/:invoice_id" component={authGuard(InvoiceDetailsPage, [ACCOUNT_MANAGER])} />

        <Route exact path="/reservation/payments" component={authGuard(PaymentPage, [ACCOUNT_MANAGER])} />
        <Route exact path="/reservation/payments/receive" component={authGuard(PaymentReceivePage, [ACCOUNT_MANAGER])} />
        <Route exact path="/reservation/payments/:payment_id" component={authGuard(PaymentDetailsPage, [ACCOUNT_MANAGER])} />

        <Route exact path="/user" component={authGuard(UserListPage)} />
        <Route exact path="/user/tenant" component={authGuard(TenantListPage, [ACCOUNT_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/user/owner" component={authGuard(OwnerListPage)} />
        <Route exact path="/user/managements" component={authGuard(ManagementListPage)} />
        <Route exact path="/user/owner/:uid" component={authGuard(OwnerPage)} />
        <Route exact path={"/user/:uid/:activeTab?"} component={authGuard(UserPage, [ACCOUNT_MANAGER, PROPERTY_MANAGER])} />
        <Route exact path="/support/tenant" component={authGuard(TenantSupportList, [SERVICE_MANAGER])} />
        <Route exact path="/support/tenant/new" component={authGuard(CreateTenantSupport, [SERVICE_MANAGER])} />
        <Route exact path="/ivr" component={authGuard(IvrCallList, [PROPERTY_MANAGER])} />
        <Route exact path="/notification/email" component={authGuard(SendEmailPage)} />

        <Route exact path="/alerts" component={authGuard(AlertsPage, [PROPERTY_MANAGER])} />

        <Route exact path="/404" component={NotFound} />
        <Route exact render={() => <Redirect to="/404" />} />
      </Switch>
      {/* Show the modal when a background page is set */}
      <Route exact path="/user/owner/new" component={authGuard(NewOwner, [PROPERTY_MANAGER])} />
      <Route exact path="/user/property-manager/new" component={authGuard(NewPropertyManager)} />
      <Route exact path="/user/new" component={authGuard(NewUser)} />
    </>
  )
}

const Loadable = Component => props =>
  (
    <Suspense
      fallback={<LinearProgress color="secondary" style={{ position: "fixed", top: 0, left: 0, width: "100%", height: 2, zIndex: 9999, backgroundColor: "rgb(255, 255, 255, 0.4)" }} />}>
      <Component {...props} />
    </Suspense>
  )

// IMPORT COMPONENTS

const Login = Loadable(lazy(() => import(/* webpackChunkName: "Login" */ "../pages/login")))
const ForgotPassword = Loadable(lazy(() => import(/* webpackChunkName: "ForgotPassword" */ "../pages/forgotPassword")))
const ResetPassword = Loadable(lazy(() => import(/* webpackChunkName: "ResetPassword" */ "../pages/resetPassword")))
const NotFound = Loadable(lazy(() => import(/* webpackChunkName: "NotFound" */ "../components/404/NotFound")))
const DashboardPage = Loadable(lazy(() => import(/* webpackChunkName: "DashboardPage" */ "../pages/dashboard")))

//property
const PropertyListPage = Loadable(lazy(() => import(/* webpackChunkName: "PropertyListPage" */ "../pages/property")))
const AddPropertyPage = Loadable(lazy(() => import(/* webpackChunkName: "AddPropertyPage" */ "../pages/property/new")))
const PropertyDetails = Loadable(lazy(() => import(/* webpackChunkName: "PropertyDetails" */ "../pages/property/[id]")))
const OwnerRequest = Loadable(lazy(() => import(/* webpackChunkName: "OwnerRequest" */ "../pages/property/request")))
const WaitingListPage = Loadable(lazy(() => import(/* webpackChunkName: "WaitingListPage" */ "../pages/property/user/waiting")))
const CreateMeterReadingPage = Loadable(lazy(() => import(/* webpackChunkName: "CreateMeterReadingPage" */ "../pages/property/meter-reading")))
const PropertyMeterReadingPage = Loadable(lazy(() => import(/* webpackChunkName: "PropertyMeterReadingPage" */ "../pages/property/[id]/meter-reading")))

//support
const TenantSupportList = Loadable(lazy(() => import(/* webpackChunkName: "TenantSupportList" */ "../pages/support/tenant")))
const CreateTenantSupport = Loadable(lazy(() => import(/* webpackChunkName: "CreateTenantSupport" */ "../pages/support/tenant/new")))

//user
const UserListPage = Loadable(lazy(() => import(/* webpackChunkName: "UserListPage" */ "../pages/user")))
const UserPage = Loadable(lazy(() => import(/* webpackChunkName: "UserPage" */ "../pages/user/[uid]")))
const OwnerListPage = Loadable(lazy(() => import(/* webpackChunkName: "OwnerListPage" */ "../pages/user/owner")))
const ManagementListPage = Loadable(lazy(() => import(/* webpackChunkName: "ManagementListPage" */ "../pages/user/managements")))
const OwnerPage = Loadable(lazy(() => import(/* webpackChunkName: "OwnerPage" */ "../pages/user/owner/[uid]")))
const TenantListPage = Loadable(lazy(() => import(/* webpackChunkName: "TenantListPage" */ "../pages/user/tenant")))
const NewPropertyManager = Loadable(lazy(() => import(/* webpackChunkName: "NewPropertyManagerModal" */ "../pages/user/property-manager/new")))
const NewOwner = Loadable(lazy(() => import(/* webpackChunkName: "NewOwnerModal" */ "../pages/user/owner/new")))
const NewUser = Loadable(lazy(() => import(/* webpackChunkName: "NewUserModal" */ "../pages/user/new")))

//reservation
const VisitListPage = Loadable(lazy(() => import(/* webpackChunkName: "VisitListPage" */ "../pages/reservation/visits")))
const InternalShiftPage = Loadable(lazy(() => import(/* webpackChunkName: "InternalShiftPage" */ "../pages/reservation/internal-shift")))
const EnquiryListPage = Loadable(lazy(() => import(/* webpackChunkName: "EnquiryListPage" */ "../pages/reservation/enquiry")))
const ReservationPage = Loadable(lazy(() => import(/* webpackChunkName: "reservation" */ "../pages/reservation")))
const BookingListPage = Loadable(lazy(() => import(/* webpackChunkName: "BookingListPage" */ "../pages/reservation/bookings")))
const CreateReservationPage = Loadable(lazy(() => import(/* webpackChunkName: "CreateReservationPage" */ "../pages/reservation/bookings/create")))
const BookingDetailsPage = Loadable(lazy(() => import(/* webpackChunkName: "BookingDetails" */ "../pages/reservation/bookings/[booking_id]")))
const SettlementPage = Loadable(lazy(() => import(/* webpackChunkName: "SettlementPage" */ "../pages/reservation/settlement")))
const CreateMoveOutPage = Loadable(lazy(() => import(/* webpackChunkName: "MoveoutTenantPage" */ "../pages/reservation/moveout")))

const InvoicePage = Loadable(lazy(() => import(/* webpackChunkName: "InvoicePage" */ "../pages/reservation/invoices")))
const InvoiceDetailsPage = Loadable(lazy(() => import(/* webpackChunkName: "InvoiceDetailsPage" */ "../pages/reservation/invoices/[invoice_id]")))
const InvoiceAddOverduePage = Loadable(lazy(() => import(/* webpackChunkName: "InvoiceAddOverduePage" */ "../pages/reservation/invoices/add-overdue")))
const InvoiceGeneratePage = Loadable(lazy(() => import(/* webpackChunkName: "InvoiceGeneratePage" */ "../pages/reservation/invoices/new")))

const PaymentPage = Loadable(lazy(() => import(/* webpackChunkName: "PaymentPage" */ "../pages/reservation/payments")))
const PaymentReceivePage = Loadable(lazy(() => import(/* webpackChunkName: "PaymentReceivePage" */ "../pages/reservation/payments/receive")))
const PaymentDetailsPage = Loadable(lazy(() => import(/* webpackChunkName: "PaymentReceivePage" */ "../pages/reservation/payments/[payment_id]")))

const IvrCallList = Loadable(lazy(() => import(/* webpackChunkName: "IvrCallList" */ "../pages/ivr")))

const SendEmailPage = Loadable(lazy(() => import(/* webpackChunkName: "SendEmailPage" */ "../pages/Notifications/Email")))
const AlertsPage = Loadable(lazy(() => import(/* webpackChunkName: "AlertsPage" */ "../pages/alerts")))
